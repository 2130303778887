import { Button, List, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useRequest } from "ahooks";
import React, { useEffect, useRef, useState } from "react";
import VirtualList from "rc-virtual-list";

import { getOrdersList } from "@/api/order";
import { getCategoryName, getLocaleDate } from "@/helpers";

const { Text } = Typography;

const perPage = 25;

export default function OrderListPage() {
  const navigate = useNavigate();
  const [listHeight, setListHeight] = useState<number>(0);
  const [list, setList] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const listRef = useRef(null);

  const { data: response } = useRequest(() => getOrdersList(page, perPage), {
    refreshDeps: [page],
  });

  const categories = JSON.parse(sessionStorage.getItem("categories") || "[]");

  useEffect(() => {
    if (response?.data?.length && Math.ceil(list.length / perPage) !== page) {
      setList((prev: any[]) => [...prev, ...response.data]);
    }
  }, [list, page, response]);

  useEffect(() => {
    // @ts-ignore
    setListHeight(listRef?.current?.clientHeight ?? 0);
  }, []);

  function handleOpen(id: number) {
    navigate(`/order/${id}`, {
      state: { title: `Заявка #${id}` },
    });
  }

  function handleScroll(e: React.UIEvent<HTMLElement, UIEvent>) {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop === listHeight &&
      page < response?.meta?.total_pages
    ) {
      setPage((prev) => prev + 1);
    }
  }

  return (
    <div className="container container_ph0">
      <div className="order-list" ref={listRef}>
        <List>
          <VirtualList
            data={list}
            height={listHeight}
            itemHeight={107}
            itemKey="id"
            onScroll={handleScroll}
          >
            {(item: any) => {
              const { id, text, created_at, order_category_id, state_rec } =
                item;

              return (
                <List.Item
                  className="order pointer"
                  onClick={() => handleOpen(id)}
                >
                  <div className="order__row">
                    <Text type="secondary">{getLocaleDate(created_at)}</Text>
                    <Text type="secondary">#{id}</Text>
                  </div>
                  <div className="order__row">
                    <Text type="secondary">
                      {getCategoryName(order_category_id, categories)}
                    </Text>
                    <Tag color={state_rec.style} style={{ margin: 0 }}>
                      {state_rec.name}
                    </Tag>
                  </div>
                  <div className="order__description">{text}</div>
                </List.Item>
              );
            }}
          </VirtualList>
        </List>
      </div>
      <div className="ph-24">
        <Button
          type="primary"
          className="mh-24"
          onClick={() =>
            navigate("/order/create", {
              state: { title: "Создать заявку" },
            })
          }
          block
        >
          Новая заявка
        </Button>
      </div>
    </div>
  );
}
