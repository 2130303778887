import axios, { AxiosError } from "axios";
import { Modal } from "antd";

function handleError(error: AxiosError) {
  const status = error?.response?.status;

  if (status && status >= 400 && status < 500) {
    if (!window.location.href.includes("/login")) {
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }

  if (status && status >= 500) {
    Modal.error({
      title: "Ошибка сервера",
      content: error?.message ?? JSON.stringify(error),
    });
    return Promise.reject(error);
  }
}

const service = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

service.interceptors.response.use((response) => response, handleError);

export default service;
