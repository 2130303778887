import sendSubscription from "@/api/subscribe/sendSubscription";

export default function handlePushSubscription() {
  Notification.requestPermission().then((result) => {
    if (result === "denied") {
      console.error("The user explicitly denied the permission request.");
    }
    if (result === "granted") {
      console.log("The user accepted the permission request.");
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration) {
          registration.pushManager.getSubscription().then((subscription) => {
            if (subscription) {
              console.log(
                "User is already subscribed.",
                JSON.stringify(subscription)
              );
              sendSubscription(subscription);
              return;
            }
            registration.pushManager
              .subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                  process.env.REACT_APP_PUBLIC_KEY
                ),
              })
              .then((subscription) => {
                console.log(
                  "A new subscription:",
                  JSON.stringify(subscription)
                );
                const oldEndpoint = localStorage.getItem("oldEndpoint");
                sendSubscription(subscription, oldEndpoint);
              });
          });
        }
      });
    }
  });
}

function urlBase64ToUint8Array(base64String: string | undefined) {
  if (base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  return "";
}
