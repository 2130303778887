import axios from "axios";

export default function getFio(query: string) {
  return axios
    .post(
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio",
      { query },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Token ${process.env.REACT_APP_DADATA_TOKEN}`,
        },
      }
    )
    .then(({ data }) => {
      return (
        data?.suggestions?.map((s: any) => ({ ...s, label: s.value })) || []
      );
    });
}
