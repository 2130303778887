import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import history from "history/browser";
import { Button } from "antd";
import { LeftOutlined, UserOutlined } from "@ant-design/icons";

import useAuth from "@/hooks/useAuth";
import Loader from "@/components/UI/Loader";
import { saveCategories, saveProfile } from "@/helpers";

export default function Layout() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth.checkAuth().then((result) => {
      if (result) {
        saveCategories();
        saveProfile();
      }
    });
    // eslint-disable-next-line
  }, []);

  const location = useLocation();
  const title = location.state?.title || "ПростоДом";
  const { isLoading } = auth;
  const isVideoPage =
    location.pathname.includes("/camera/") &&
    location.pathname !== "/camera/list";

  function handleBack() {
    history.back();
  }

  function handleOpenProfile() {
    navigate("/profile", { state: { title: "Профиль" } });
  }

  return (
    <>
      {!isLoading &&
        auth.isAuth &&
        location.pathname !== "/login" &&
        !isVideoPage && (
          <header className="header">
            <div className="header__left">
              {location.pathname !== "/" && (
                <Button
                  type="link"
                  icon={<LeftOutlined />}
                  onClick={handleBack}
                />
              )}
            </div>
            <div className="header__center">
              <div className="header__title">{title}</div>
            </div>
            <div className="header__right">
              {location.pathname !== "/" &&
                location.pathname !== "/profile" &&
                !location.pathname.includes("camera") && (
                  <Button
                    type="link"
                    icon={<UserOutlined />}
                    onClick={handleOpenProfile}
                  />
                )}
            </div>
          </header>
        )}
      <main className={isVideoPage ? "bg-black" : ""}>
        {isLoading && <Loader />}
        {!isLoading && <Outlet />}
      </main>
    </>
  );
}
