import { useParams } from "react-router-dom";
import { Col, Row, Tag, Typography, Image } from "antd";
import { useRequest } from "ahooks";

import { getOrderDetails } from "@/api/order";
import { getCategoryName, getLocaleDate } from "@/helpers";
import Loader from "@/components/UI/Loader";

const { Text, Link } = Typography;

export default function OrderPage() {
  const params = useParams();

  function getOrder() {
    return getOrderDetails(params.id);
  }

  const { data, loading } = useRequest(getOrder);

  const categories = JSON.parse(sessionStorage.getItem("categories") || "[]");

  return (
    <div className="container container_ph24">
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="mb-16">
            <Text type="secondary">Дата размещения</Text>
            <Text className="block">{getLocaleDate(data.created_at)}</Text>
          </div>
          <div className="mb-16">
            <Text type="secondary">Номер заявки</Text>
            <Text className="block">{data.id}</Text>
          </div>
          <div className="mb-16">
            <Text type="secondary" className="block">
              Статус заявки
            </Text>
            <Tag color={data.state_rec.style}>{data.state_rec.name}</Tag>
          </div>
          <div className="mb-16">
            <Text type="secondary">Категория</Text>
            <Text className="block">
              {getCategoryName(data.order_category_id, categories)}
            </Text>
          </div>
          <div className="mb-16">
            <Text>{data.text}</Text>
          </div>
          <Row gutter={[8, 8]} align={"stretch"} className="mb-16">
            <Image.PreviewGroup>
              {!!data.photos?.length &&
                data.photos?.map((p: string) => (
                  <Col span={6} key={p}>
                    <Image
                      src={p}
                      className="img-cover"
                      rootClassName="image-wrapper"
                    />
                  </Col>
                ))}
            </Image.PreviewGroup>
          </Row>
          <div className="mb-16">
            <Text type="secondary">Адрес</Text>
            <Text className="block">{data.address.value}</Text>
          </div>
          <div className="mb-16">
            <Text type="secondary">ФИО</Text>
            <Text className="block">{data.customer_name.value}</Text>
          </div>
          <div className="mb-16">
            <Text type="secondary">Телефон</Text>
            <Link href={`tel:${data.customer_phone}`} className="block">
              {data.customer_phone}
            </Link>
          </div>
        </>
      )}
    </div>
  );
}
