import useAuth from "@/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Button, Form, Input } from "antd";
import React, { useState } from "react";
import { LogoutOutlined } from "@ant-design/icons";
// @ts-ignore
import InputMask from "react-input-mask";

import { updateProfile } from "@/api/profile";
import { getFio } from "@/api/suggestions";

export default function ProfilePage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [nameOptions, setNameOptions] = useState<any[]>([]);

  const profile = JSON.parse(sessionStorage.getItem("profile") || "{}");
  function handleSubmit(values: any) {
    // todo: edit email?
    const { name, address, phone, email } = values;

    setLoading(true);
    const requestData = {
      phone,
      email,
      address,
      name,
    };

    updateProfile(requestData)
      .then((data) => {
        sessionStorage.setItem("profile", JSON.stringify(data));
        navigate("/");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onNameSearch(query: string) {
    getFio(query).then((data) => {
      setNameOptions(data);
    });
  }

  function handleSignOut() {
    auth.signout(() => {
      navigate("/login");
    });
  }

  return (
    <div className="container container_ph24">
      <Form
        name="profile"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
        }}
        initialValues={{
          remember: true,
          name: profile?.name || "",
          address: profile?.address?.value || "",
          phone: profile?.phone || "",
          email: profile?.email || "",
        }}
        autoComplete="off"
        disabled={loading}
        onFinish={handleSubmit}
      >
        <div>
          <Form.Item
            name="name"
            label="ФИО"
            labelCol={{
              xs: 24,
              md: 4,
            }}
          >
            <AutoComplete
              options={nameOptions}
              placeholder={""}
              onSearch={onNameSearch}
            />
          </Form.Item>
          <Form.Item
            name="address"
            required
            label="Адрес"
            labelCol={{
              xs: 24,
              md: 4,
            }}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="phone"
            required
            label="Телефон"
            rules={[
              {
                required: true,
                message: "Введите ваш номер телефона",
              },
              {
                pattern: /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/g,
                message: "Неверный номер телефона",
              },
            ]}
            labelCol={{
              xs: 24,
              md: 4,
            }}
          >
            <InputMask
              className="ant-input phone"
              type="tel"
              mask={"+7(999)999-99-99"}
              alwaysShowMask
              placeholder="Номер телефона"
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={
              [
                // {
                //   required: true,
                //   message: "Введите ваш email",
                // },
                // {
                //   type: "email",
                //   message: "Неверный email",
                // },
              ]
            }
            labelCol={{
              xs: 24,
              md: 4,
            }}
          >
            <Input type="email" placeholder="Ваша электронная почта" disabled />
          </Form.Item>
        </div>
        <div>
          <Button type="primary" className="mb-20" htmlType="submit" block>
            Сохранить
          </Button>
          <Button
            type="link"
            icon={<LogoutOutlined />}
            onClick={handleSignOut}
            block
          >
            Выход
          </Button>
        </div>
      </Form>
    </div>
  );
}
