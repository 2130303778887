import axios from "axios";

export default function uploadPhoto(
  url: string,
  data: any,
  contentType: string
) {
  return axios
    .put(url, data, {
      headers: {
        // "Transfer-Encoding": "chunked",
        "Cache-Control": "max-age=31536000",
        "Content-Type": contentType,
        "x-amz-acl": "public-read",
      },
    })
    .then(({ data }) => {
      return data;
    });
}
