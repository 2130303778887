import service from "@/api/client";

export default function sendSubscription(
  subscription: any,
  oldEndpoint: string | null = null
) {
  service.post(
    "/api/user/webpushes/subscribe",
    JSON.stringify({
      ...JSON.parse(JSON.stringify(subscription)),
      ...(oldEndpoint && { oldEndpoint }),
    })
  );
}
