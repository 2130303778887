export default function getNameValue(name: string, nameOptions: any[]) {
  let result = {
    value: name,
  };

  const selectedName = nameOptions.length
    ? nameOptions.find((o: any) => o.value === name)
    : null;

  if (selectedName) {
    result = selectedName;
  }

  return result;
}
