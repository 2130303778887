import { useNavigate, useParams } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import history from "history/browser";
import { useRequest } from "ahooks";
import ReactPlayer from "react-player/file";

import Loader from "@/components/UI/Loader";
import { getCameraDetails } from "@/api/camera";
import { useEffect } from "react";

export default function CameraPage() {
  const params = useParams();
  const navigate = useNavigate();

  const { data, loading } = useRequest(() => getCameraDetails(params.id));

  useEffect(() => {
    if (data && data.state !== "active") {
      navigate("/camera/list", { replace: true });
    }
  }, [data, navigate]);

  function handleClose() {
    history.back();
  }

  function handleError(error: any) {
    console.log(error);
  }

  return (
    <div className="container container_video">
      {loading && <Loader />}
      {!loading && (
        <div className="video">
          <ReactPlayer
            url={data.url}
            muted
            playsinline
            playing
            width="100%"
            height="100%"
            config={{
              forceHLS: true,
            }}
            onError={handleError}
          />

          <div className="video__header">
            {data.description}
            <Button
              className="video__close"
              onClick={handleClose}
              type="link"
              icon={<CloseOutlined />}
            />
          </div>
        </div>
      )}
    </div>
  );
}
