import { List, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { getCamerasList } from "@/api/camera";
import { useRequest } from "ahooks";
import Loader from "@/components/UI/Loader";

const { Text } = Typography;

export default function CamerasListPage() {
  const navigate = useNavigate();

  const { data, loading } = useRequest(getCamerasList);

  function getCameraState(state: string) {
    return state === "active"
      ? {
          value: "активна",
          style: "success",
        }
      : {
          value: "отключена",
          style: "error",
        };
  }

  function handleOpen(id: number, state: string) {
    if (state === "active") {
      navigate(`/camera/${id}`);
    }
  }

  return (
    <div className="container container_ph24">
      {loading && <Loader />}
      {!loading && (
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item
              onClick={() => handleOpen(item.id, item.state)}
              className="pointer"
            >
              <Text>{item.description}</Text>
              <Tag
                color={getCameraState(item.state).style}
                style={{
                  margin: 0,
                }}
              >
                {getCameraState(item.state).value}
              </Tag>
            </List.Item>
          )}
        />
      )}
    </div>
  );
}
