import React from "react";
import { CheckAuth, SignIn, SignOut } from "@/api/auth";
import { subscribe, unsubscribe } from "@/helpers/";

interface AuthContextType {
  isAuth: boolean;
  isLoading: boolean;
  signin: (
    data: any,
    callback: VoidFunction,
    errorCallback: VoidFunction
  ) => void;
  signout: (callback: VoidFunction) => void;
  checkAuth: () => Promise<boolean>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuth, setIsAuth] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const signin = (
    data: any,
    callback: VoidFunction,
    errorCallback: VoidFunction
  ) => {
    const { login, password } = data;

    return SignIn(login, password)
      .then((isAuth) => {
        setIsAuth(isAuth);
        callback();
        unsubscribe().then(() => {
          subscribe();
        });
      })
      .catch((error) => {
        errorCallback();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const signout = (callback: VoidFunction) => {
    return SignOut()
      .then(() => {
        setIsAuth(false);
        unsubscribe();
        callback();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkAuth = () => {
    return CheckAuth()
      .then((isAuth) => {
        setIsAuth(isAuth);
        subscribe();
        return true;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const value = { isAuth, isLoading, signin, signout, checkAuth };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
  return React.useContext(AuthContext);
}
