import React, { useState } from "react";
import { AutoComplete, Button, Form, Input, Radio, Upload } from "antd";
import { CloseCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import InputMask from "react-input-mask";

import { getAddress, getFio } from "@/api/suggestions";
import { createOrder, getUploadLink, uploadPhoto } from "@/api/order";
import { getAddressValue, getNameValue } from "@/helpers";

export default function CreateOrderPage() {
  const navigate = useNavigate();
  const [addressOptions, setAddressOptions] = useState<any[]>([]);
  const [nameOptions, setNameOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const categories = JSON.parse(
    sessionStorage.getItem("categories") || "[]"
  ).map((c: any) => ({
    label: c.name,
    value: c.id,
  }));

  const profile = JSON.parse(sessionStorage.getItem("profile") || "{}");

  function onAddressSearch(query: string) {
    getAddress(query).then((data) => {
      setAddressOptions(data);
    });
  }

  function onNameSearch(query: string) {
    getFio(query).then((data) => {
      setNameOptions(data);
    });
  }

  function handleSubmit(values: any) {
    const {
      customer_name,
      address,
      photos,
      customer_phone,
      order_category_id,
      text,
    } = values;

    setLoading(true);

    const guid = crypto.randomUUID
      ? crypto.randomUUID()
      : new Date().getTime().toString(16);

    function sendCreateRequest(photos: string[] = []) {
      const requestData = {
        text,
        order_category_id,
        customer_phone,
        address: getAddressValue(address, addressOptions),
        customer_name: getNameValue(customer_name, nameOptions),
        photos,
      };

      createOrder(requestData).then(() => {
        setLoading(false);
        navigate("/order/list", {
          state: { title: "Мои заявки" },
          replace: true,
        });
      });
    }

    if (photos && photos?.fileList?.length) {
      Promise.all(
        photos.fileList.map((f: any) => {
          const { name, type } = f;
          return getUploadLink(name, type, guid);
        })
      )
        .then((data: any[]) => {
          const sendPhotos: string[] = data.map((res: any) => res.signedUrl);
          return Promise.all(
            sendPhotos.map((url: string, index: number) => {
              const file = photos.fileList[index];
              return uploadPhoto(url, file.originFileObj, file.type);
            })
          ).then(() => {
            sendCreateRequest(sendPhotos);
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      sendCreateRequest();
    }
  }

  return (
    <div className="container container_ph24">
      <Form
        name="order"
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        initialValues={{
          remember: true,
          address: profile?.address || "",
          customer_name: profile?.name || "",
          customer_phone: profile?.phone || "",
        }}
        autoComplete="off"
        onFinish={handleSubmit}
        disabled={loading}
      >
        <Form.Item
          name="address"
          required
          label="Адрес"
          validateTrigger="onSelect"
          rules={[
            {
              required: true,
              message: "Поле обязательно",
            },
          ]}
          labelCol={{
            xs: 24,
            md: 10,
          }}
        >
          <AutoComplete
            options={addressOptions}
            placeholder={"Адрес подачи заявки"}
            onSearch={onAddressSearch}
          />
        </Form.Item>
        <Form.Item
          name="order_category_id"
          required
          label="Категория"
          rules={[
            {
              required: true,
              message: "Поле обязательно",
            },
          ]}
          labelCol={{
            xs: 24,
            md: 10,
          }}
        >
          <Radio.Group>
            {categories.map((c: any) => (
              <Radio value={c.value} key={c.value}>
                {c.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="text"
          label="Заявка"
          labelCol={{
            xs: 24,
            md: 10,
          }}
        >
          <Input.TextArea placeholder="Опишите проблему" />
        </Form.Item>
        <Form.Item name="photos" valuePropName={"file"}>
          <Upload
            listType="picture-card"
            accept="capture=camera,image/*"
            beforeUpload={() => false}
            showUploadList={{
              showPreviewIcon: false,
              removeIcon: (
                <CloseCircleFilled style={{ color: "#1890FF", fontSize: 24 }} />
              ),
            }}
          >
            <PlusOutlined style={{ fontSize: 32 }} />
          </Upload>
        </Form.Item>
        <Form.Item
          name="customer_name"
          required
          label="Контактное лицо"
          rules={[
            {
              required: true,
              message: "Поле обязательно",
            },
            {
              pattern: /^(\D*)$/g,
              message: "Введите имя контактного лица",
            },
          ]}
          labelCol={{
            xs: 24,
            md: 10,
          }}
        >
          <AutoComplete
            options={nameOptions}
            placeholder={"Как к Вам обращаться"}
            onSearch={onNameSearch}
          />
        </Form.Item>
        <Form.Item
          name="customer_phone"
          required
          label="Телефон контактного лица"
          rules={[
            {
              required: true,
              message: "Поле обязательно",
            },
            {
              pattern: /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/g,
              message: "Введите телефон",
            },
          ]}
          labelCol={{
            xs: 24,
            md: 10,
          }}
        >
          <InputMask
            className="ant-input phone"
            type="tel"
            mask={"+7(999)999-99-99"}
            alwaysShowMask
            placeholder="Номер телефона"
          />
        </Form.Item>
        <Button type="primary" block htmlType="submit">
          Создать
        </Button>
      </Form>
    </div>
  );
}
