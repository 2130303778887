import { LoadingOutlined } from "@ant-design/icons";

export default function Loader() {
  return (
    <div className="container container_center">
      <LoadingOutlined
        style={{
          color: "#1890FF",
          fontSize: 32,
        }}
      />
    </div>
  );
}
