import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export default function MainPage() {
  const navigate = useNavigate();

  return (
    <div className="container container_center">
      <div className="content">
        <img src="logo512.png" alt="logo" className="img-fluid mb-40" />
        <Button
          type="primary"
          onClick={() =>
            navigate("/order/create", {
              state: { title: "Создать заявку" },
            })
          }
          block
          className="mb-20"
        >
          Создать заявку
        </Button>
        <Button
          type="primary"
          onClick={() =>
            navigate("/order/list", { state: { title: "Мои заявки" } })
          }
          block
          ghost
          className="mb-20"
        >
          Мои заявки
        </Button>
        <Button
          type="primary"
          onClick={() => navigate("/profile", { state: { title: "Профиль" } })}
          block
          ghost
          className="mb-20"
        >
          Профиль
        </Button>
        <Button
          type="primary"
          onClick={() =>
            navigate("/camera/list", {
              state: { title: "Камеры видеонаблюдения" },
            })
          }
          block
          ghost
          className="mb-20"
        >
          Камеры
        </Button>
      </div>
    </div>
  );
}
