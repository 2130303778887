import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "./App.less";
import useAuth, { AuthProvider } from "@/hooks/useAuth";
import LoginPage from "@/components/pages/LoginPage";
import Layout from "@/components/UI/Layout";
import MainPage from "@/components/pages/MainPage";
import ProfilePage from "@/components/pages/ProfilePage";
import CreateOrderPage from "@/components/pages/CreateOrderPage";
import OrderListPage from "@/components/pages/OrdersListPage";
import CamerasListPage from "@/components/pages/CamerasListPage";
import OrderPage from "@/components/pages/OrderPage";
import CameraPage from "@/components/pages/CameraPage";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <MainPage />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            }
          />
          <Route
            path="/order/create"
            element={
              <RequireAuth>
                <CreateOrderPage />
              </RequireAuth>
            }
          />
          <Route
            path="/order/list"
            element={
              <RequireAuth>
                <OrderListPage />
              </RequireAuth>
            }
          />
          <Route
            path="/order/:id"
            element={
              <RequireAuth>
                <OrderPage />
              </RequireAuth>
            }
          />
          <Route
            path="/camera/list"
            element={
              <RequireAuth>
                <CamerasListPage />
              </RequireAuth>
            }
          />
          <Route
            path="/camera/:id"
            element={
              <RequireAuth>
                <CameraPage />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();

  if (!auth.isAuth && !auth.isLoading) {
    return <Navigate to="/login" replace />;
  }

  return children;
}
