import service from "@/api/client";

export default function getUploadLink(
  name: string,
  type: string,
  guid: string
) {
  return service
    .get(`/api/v1/s3/sign?objectName=${name}&contentType=${type}&guid=${guid}`)
    .then(({ data }) => {
      return data;
    });
}
